import { createSlice } from '@reduxjs/toolkit';

type AuthModalState = {
  show: boolean;
};

const initialState: AuthModalState = {
  show: false,
};

export const { reducer: authModalReducer, actions } = createSlice({
  name: 'authModal',
  initialState,
  reducers: {
    show(state) {
      state.show = true;
    },
    hide(state) {
      state.show = false;
    },
  },
});
