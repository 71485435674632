import { authReducer as auth } from '@ott/auth';
import { limiter } from '@ott/superagent';

import { combineReducers } from '@reduxjs/toolkit';

import { authModalReducer as authModal } from './authModal';

export const commonReducer = combineReducers({
  auth,
  limiter: limiter.reducer,
  authModal,
});
