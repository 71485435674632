import React from 'react';
import { Provider } from 'react-redux';
import { LayoutContextProvider } from '@ott/layout-context';
import store from './src/redux/store';
import createGoogleTagManager from './src/utilities/googleTagManager';

setTimeout(() => {
  createGoogleTagManager();
}, 1000);

const WrapWithProvider = ({ element }) => (
  <Provider store={store}>
    <LayoutContextProvider>{element}</LayoutContextProvider>
  </Provider>
);

export default WrapWithProvider;
