import { combineReducers } from 'redux';
// @ts-ignore
import { reducer as form } from 'redux-form';

import { commonReducer as common } from './common';

export const rootReducer = combineReducers({
  form,
  common,
});
