const GTM_CONTAINER_ID = 'GTM-M85GWRM8';

function createIFrame() {
  const iframe = document.createElement('iframe');

  iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`;
  iframe.height = 0;
  iframe.width = 0;
  iframe.style.cssText = 'display:none;visibility:hidden';

  document.body.appendChild(iframe);
}

function createScript() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  const firstScriptNode = document.getElementsByTagName('script')[0];
  const script = document.createElement('script');

  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_CONTAINER_ID}`;

  firstScriptNode.parentNode.insertBefore(script, firstScriptNode);
}

export default function googleTagManager() {
  if (typeof window === 'undefined') {
    return;
  }

  createScript();
  createIFrame();
}
